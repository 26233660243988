import React, { Fragment, useState } from "react";
import { Link } from "react-router-dom";

const DotDiv = ({ color }) => {
    const baseClasses = "w-4 h-4 rounded-full";
    const colorClasses = color === "green" ? " bg-dol-green" : " bg-red-700";
    return (
        <div className={baseClasses + colorClasses}></div>
    )
}

const ArrowButton = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-6">
            <path fillRule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25Zm4.28 10.28a.75.75 0 0 0 0-1.06l-3-3a.75.75 0 1 0-1.06 1.06l1.72 1.72H8.25a.75.75 0 0 0 0 1.5h5.69l-1.72 1.72a.75.75 0 1 0 1.06 1.06l3-3Z" clipRule="evenodd" />
        </svg>
    )
}

const Sensor = ({ items, cardTitle, mac, deviceStatus, isOnline }) => {
    return (
        <Fragment>
            <div className='flex items-center justify-center min-h-0 mb-10'>
                <div className='w-full py-8 mx-auto bg-white rounded-lg shadow-xl md:w-1/2'>
                    <div className='max-w-sm px-4 mx-auto space-y-6 md:space-y-3'>
                        <h2 className="text-2xl font-bold text-dol-blue">{cardTitle}</h2>
                        {!isOnline && <p className="text-xs">To add and remove sensors, the gateway must be online</p>}
                        {items.map((item, index) => (
                            <Link to={`/devices/${mac}/sensors/${item.devEui}`} state={{ item, mac, deviceStatus }} key={index} className="flex justify-between items-center p-2 rounded-xl border-b border-gray-300">
                                <div className="flex-[2]">{item.name}</div>
                                <div className="flex-1">{item.sensorType}</div>
                                <div className="flex-1 content-center flex justify-center">{deviceStatus.sensorsInactive && deviceStatus.sensorsInactive.inactiveSensors.some(s => s.devEui == item.devEui) ? <DotDiv color={"red"} /> : <DotDiv color={"green"} />}</div>
                                <ArrowButton className="flex-1" />
                            </Link>
                        ))}
                    </div>
                    <div className="flex justify-end pt-8 pr-4 max-w-sm mx-auto">
                        {isOnline &&  
                            <Link to={`/add/sensor/${mac}`} state={mac} className="">
                                <button className="inline-block rounded bg-dol-green px-4 pb-[5px] pt-[6px] text-xs font-medium uppercase leading-normal text-white shadow-primary-3 transition duration-150 ease-in-out hover:bg-primary-accent-300 hover:shadow-primary-2 focus:bg-primary-accent-300 focus:shadow-primary-2 focus:outline-none focus:ring-0 active:bg-primary-600 active:shadow-primary-2 motion-reduce:transition-none dark:shadow-black/30 dark:hover:shadow-dark-strong dark:focus:shadow-dark-strong dark:active:shadow-dark-strong">+ Add</button>
                            </Link>
                        }
                        {!isOnline &&  
                            <button disabled={true} className="inline-block rounded bg-dol-green px-4 pb-[5px] pt-[6px] text-xs font-medium uppercase leading-normal text-white shadow-primary-3 transition duration-150 ease-in-out hover:bg-primary-accent-300 hover:shadow-primary-2 focus:bg-primary-accent-300 focus:shadow-primary-2 focus:outline-none focus:ring-0 active:bg-primary-600 active:shadow-primary-2 motion-reduce:transition-none dark:shadow-black/30 dark:hover:shadow-dark-strong dark:focus:shadow-dark-strong dark:active:shadow-dark-strong">+ Add</button>
                        }
                    </div>
                </div>
            </div>
        </Fragment>

    )

}

export default Sensor;
